#root {
	display: block;
	width: 100vw;
	height: 100vh;
}

.leaflet-container {
	width: 100vw;
	height: 100vh;
}

.minimap-frame {
	height: 200px;
	width: 200px;
	background-image: url("./images/frames/mini-map.png");
	background-size: cover;
	background-repeat: no-repeat;
}

.minimap {
	left: 25px;
	top: 25px;
	box-shadow: -4px 4px 16px 6px rgba(0, 0, 0, 0.8);
	border-radius: 50%;
	border: #000000 15px;
}

.leaflet-bar {
	border: none !important;
}

.characters {
	position: absolute; 
	left: 0; 
	right: 0; 
	margin-left: auto; 
	margin-right: auto; 
	bottom: 150px;
	background: #000000;
	height: 214px;
	width: 800px;
	z-index: 99999999999;
	background-image: url("./images/frames/characters.png");
	background-size: cover;
	background-repeat: no-repeat;
}